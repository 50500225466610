/**
* _menustyle.scss v-1.01
* sebcoach94.fr
*/

// ------------------------- Stop Fouc ---/
.no-js #top-menu-wrapper {
  display: none;
}

@media screen and (min-width: 40em) {
  .no-js #top-menu-wrapper {
    display: block;
  }

  .no-js #mob_menu_panel {
    display: none;
  }
}

// -------------------------  header ---/
$sticky-shrink-menu-height: rem-calc(80);

$sticky-shrink-header-height: rem-calc(80);
$sticky-shrink-header-shrunk-height: rem-calc(100);

$sticky-shrink-header_sh-height: 20vh;
$sticky-shrink-header_sh-shrunk-height: rem-calc(64);


$header-shrink-height: 12vh; // not yet used
$header-shrink_sh-height: 12vh; // not yet used
$sticky-shrink-hero_min-height: $sticky-shrink-menu-height; // not yet used


// -------------------------  hero --- wImg / 
$hero_wImg-Mheight: 20vh;
$hero_wImg-height: 50vh;
$hero_noImg-height: $sticky-shrink-menu-height;

$hero_sh_wImg-Mheight: 20vh;
$hero_sh_wImg-height: 30vh;
$hero_sh_noImg-height: $sticky-shrink-menu-height;

.hero {
  //margin-top: $sticky-shrink-menu-height; // _style.scss
  min-height:  rem-calc(0);
  margin-bottom: rem-calc(0);
  //background-color: rgba($dark-gray, 0.5); // _style.scss
}

.hero.wImg {
  margin-top: $sticky-shrink-menu-height;
  margin-bottom: rem-calc(0);
  min-height: $hero_wImg-Mheight;
  background-size: cover;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-color: $col3;

    @include breakpoint(medium) {
      min-height: $hero_wImg-height;
      background-size: cover; //auto $sticky-shrink-hero-height;// auto;
    }
}


.hero_sh.wImg {
  margin-top: $sticky-shrink-menu-height;
  margin-bottom: rem-calc(0);
  min-height: $hero_sh_wImg-Mheight;
  background-size: auto $hero_sh_wImg-height;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-color: $col3;

    @include breakpoint(medium) {
      min-height: $hero_sh_wImg-height;
      background-size: cover; //auto $sticky-shrink-hero-height;// auto;
    }
}
.hero_sh {
  margin-top: $sticky-shrink-menu-height;
  min-height:  rem-calc(0);
  margin-bottom: rem-calc(0);
  background-color: rgba($dark-gray, 0.5);
}

// ------------------------- header ---/
#header{
  background-color: rgba($dark-gray, 1.0);
}

// ------------------------- top-menu-wrapper ---/
#top-menu-wrapper{
  height: $sticky-shrink-menu-height;
  background-color: rgb(11,79,95);
  background: linear-gradient(90deg, rgba(11,79,95,1) 0%, rgba(58,113,125,1) 100%);

  transition: all 0.4s ease;
  display: flex;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: center;
  //line-height: $sticky-shrink-menu-height;
  //width: 100%; 
}


#search {   
  flex-basis: 20%;
  align-self: center;

  @include breakpoint(xlarge) {
    flex-basis: 30%;
  }

  .fa {
    color: $white;
    font-size: 1.5rem;
  }
}


#large-menu {
  @include show-for(xlarge);
  flex-basis: 60%;
  align-self: right;
}


#large-logo {
  @include show-for(xlarge);
  flex-basis: 20%;
  margin-bottom: rem-calc(18);
  margin-top: rem-calc(18);
  text-align: center;

  img {
    height: rem-calc(60);
    width:auto;
  }

}


#mobile-logo {
  @include hide-for(xlarge);
  flex-basis: 40%;
  margin-bottom: rem-calc(6);
  margin-top: rem-calc(6);
  text-align: center;

  img {
    height: rem-calc(60);
    width:auto;
  }

}


#mobile-menu-icon {
  @include hide-for(xlarge);
  padding-left: rem-calc(8);padding-right: rem-calc(8);
  flex-basis: 30%;
  align-self: center;
  display: flex;
  justify-content: center;// flex-end;
}


// ------------------------- site Menu ---/

// ------------------------- mobile Menu ---/
$black: #111111;// lanceC
$dark-gray: #333333;// lanceC

#mobile-menu {
  a{
      color: #333333;
      font-weight: 400;
  }


  li.level1 {
    > a{
      color: #333333;
    }
  }
  li.level1.is-active {
    border-left: 2px solid #AA2B0E;
      > a{
        color: #222222;
        font-weight: 600;
      }
  } 

  li.level2 {
    > a{
      color: #333333;
    }
  }
  li.level2.is-active {
    border-left: 2px solid #AA2B0E;
      > a{
        color: #222222;
        font-weight: 600;
      }
  } 

  li.level3 {
    > a{
      color: #333333;
      //color: rgba($col4, 0.4);
    }
  }
  li.level3.is-active {
    border-left: 2px solid #AA2B0E;
      > a{
        color: #222222;
        font-weight: 600;
      }
  } 

//.submenu-toggle {
//  color: rgba($col4, 1.0);
//}


} // end #mobile-menu




.menu-icon {
    width: 40px;
    height: 16px;
}
.menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    background: $white;
    -webkit-box-shadow: 0 7px 0 $white, 0 14px 0 $white;
    box-shadow: 0 7px 0 $white, 0 14px 0 $white;
    content: '';
}

.mobilePanel_logo {
  text-align: center;
  margin-bottom: 2rem;
  padding: rem-calc(12 0);
  background: $dark-gray;
    img {
      height: rem-calc(30);
      width:auto;
    }
}

// ------------------------- sticky-shrink ---/
#header.sticky-shrink-header {
  z-index: 9999;
  width: 100%;
  //height: $sticky-shrink-header-height;
  position: fixed;
  top: 0;
  left: 0;
  //overflow: hidden;
  transition: all 0.7s ease;
  border-top: 1px solid $col4;

  a.logo {
    display: flex;
    //text-align: center;

    img{
    position:absolute;left:0;top:0;
    transition: all .5s ease;
    }      
      img.logo-l{
      transform: scale(1.0);
      opacity:1;
      }
      img.logo-s{
      transform: scale(0.7);
      opacity:0;
      }


    .menu a{
      padding: rem-calc(0);
    }
  }
}

#header.sticky-shrink-header.sh {
  //height: $sticky-shrink-header_sh-height;
}


// #header.shrunk
#header.sticky-shrink-header.shrunk{
  height: $sticky-shrink-header_sh-shrunk-height;
  //height: auto;
  background-color: $col3;
  transition: all 0.7s ease;

  a.logo {
    //text-align: center;
    
      img.logo-l{
      transform: scale(0.9);
      opacity:0;
      }
      img.logo-s{
      transform: scale(0.9);
      opacity:1;
      }

    .menu a{
      padding: rem-calc(0);
    }
  }


}

#header.sticky-shrink-header.sh.shrunk{
  height: $sticky-shrink-header_sh-shrunk-height;

  a.logo {
    
      img.logo-l{
      transform: scale(0.7);
      }
      img.logo-s{
      transform: scale(0.6) translateY(-38%);
      }

  }

}


// ------------------------- mega-menu ---/

.mega-menu {
  .dropdown-pane {
    width: 150%;//100%;
    max-width: 100vw;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
    background-color: $white; //$dark-gray; 
    border: 1px solid rgba($dark-gray, 0.4);
  }

  .menu {
    background: none;
  }

  ul.w100 {
    width: 100%;
    min-width: 100%;
  }

  > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: $white transparent transparent;
    margin-left: 10px;
    margin-top: 9px;
  }
}


// ------------------------- mega-menu column titles---/
$MMactive: #C8B478;// lanceC
$MMhover: #AA2B0E;// rgba(#AA2B0E, 0.4);
$text-light: #e6e6e6;// lanceC
$black: #111111;// lanceC
$dark-gray: #333333;// lanceC

#mega-menu-container {


  li.level1 {
    > a{
      text-decoration: none;
      font-weight: 400;
      color: $white;

      &:hover {
        color: $MMhover;
      }
    }
    a.is-active{
      color: $MMactive
    }

    ul{
      list-style: none;
    }
  }

  li.level1p {
    > a{
      text-decoration: none;
      font-weight: 400;
      color: $white;
      
      &:hover {
        color: $white;
      }
    }
  }  


  li.level2 {
    > a{
      text-decoration: none;
      font-weight: 800;
      color: $col2;

      &:hover {
        color: $MMhover;
      }
    }
    > a.is-active{
      color: $MMactive;
    }
    ul{
      list-style: none;
    }
  }

  li.level2p {
    > a{
      //cursor:  not-allowed;// auto default none context-menu help pointer wait not-allowed
      text-decoration: underline;
      font-weight: 800;
      color: $col2;

      &:hover {
        color: $MMhover;
      }
    }
    > a.is-active{
      color: $MMactive;
    }
    ul{
      list-style: none;
    }
  }


  li.level3 {
    > a{
      //cursor: pointer;// auto default none context-menu help pointer wait not-allowed
      text-decoration: none;
      font-weight: 400; 
      color: $col2;

      &:hover {
        color: $MMhover;
      }
    }
    > a.is-active{
      color: $MMactive;
    }
    ul{
      list-style: none;
    }
  }

  li.level3p {
    > a{
      //cursor: pointer;// auto default none context-menu help pointer wait not-allowed
      text-decoration: none;
      font-weight: 400; 
      color: $col2;

      &:hover {
        color: $MMhover;
      }
    }
    > a.is-active{
      color: $MMactive;
    }
    ul{
      list-style: none;
    }
  }

}


// ------------------------- breadcrumbs ---/
#breadcrumbs {
  z-index: 9998;
  background-color: rgba($dark-gray, 0.5);
  position: -webkit-sticky;
  position: sticky;
  //top: $sticky-shrink-header_sh-shrunk-height; //4rem;
  bottom: 0;
  //margin-top: rem-calc(6);
  padding-top: rem-calc(3);
  padding-bottom: rem-calc(3);

  ul {
    padding: 0;
    margin: 0;
  }
  li {
    a{
      color: $white;
    }
    span{
      color: $col4;
      font-weight: 600;
    }
  }

  @include breakpoint(large) {
    padding-top: rem-calc(6);
    padding-bottom: rem-calc(6);
  }
}


// --- mobile Menu search ---/
.sisea-search-form {
    
    & input {
        width: 90%;
        padding: 12px 24px;
        margin: 0 5%;        
        background-color: transparent;
        transition: transform 250ms ease-in-out;
        font-size: 14px;
        line-height: 18px;
        
        color: $white;//$col4;
        background-color: transparent;
 
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M20.667,18.667L19.613,18.667L19.24,18.307C20.547,16.787 21.333,14.813 21.333,12.667C21.333,7.88 17.453,4 12.667,4C7.88,4 4,7.88 4,12.667C4,17.453 7.88,21.333 12.667,21.333C14.813,21.333 16.787,20.547 18.307,19.24L18.667,19.613L18.667,20.667L25.333,27.32L27.32,25.333L20.667,18.667ZM12.667,18.667C9.347,18.667 6.667,15.987 6.667,12.667C6.667,9.347 9.347,6.667 12.667,6.667C15.987,6.667 18.667,9.347 18.667,12.667C18.667,15.987 15.987,18.667 12.667,18.667Z' style='fill:rgb(255,255,255);fill-opacity:0.9;fill-rule:nonzero;'/%3E%3Crect x='0' y='0' width='24' height='24' style='fill:none;fill-rule:nonzero;'/%3E%3C/svg%3E%0A");
        fill: $white;//$col4;
        border: none;
        box-shadow: none;
        transition: all 250ms ease-in-out;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        background-repeat: no-repeat;
        background-size: rem-calc(32 32);//18px 18px;
        background-position: center center;        
        @include breakpoint(medium) {
          background-position: 95% center;
        }

        &::placeholder {
            color: rgba($white, 0.9);
            letter-spacing: 1.5px;
        }
        
        &:hover,
        &:focus {
            box-shadow: none;
            padding: 12px 0;
            outline: 0;
            border: 1px solid transparent;
            border-bottom: 1px solid $white;
            border-radius: 0;
            background-color: transparent; //lanceC
            background-position: 100% center;
        }
    }
}

/**************************************************************  #msearch */
#msearch-area{
  
}


#msearch-results {
    @include xy-grid-container($grid-container, $grid-container-padding);    
    width: 100%;
    align-items: center;

  position: relative;
  padding:rem-calc(16);
  width: 100%;
  margin-bottom: 1rem;
  margin-top:rem-calc(18);
  color: $col3;
  background-color: $white;
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-start;
  //align-items: center;


  p {

  }
}
// --- mobile Menu /search ---/