/**
* _style.scss v-1.01
* sebcoach94.fr
*/


// -------------------------  body ---/


// -------------------------  masthead ---/

#masthead {

  #logoBar-sm {
      //height:rem-calc(120);
      display:block;
      position: relative;
      padding-top: rem-calc(4);padding-bottom: rem-calc(4);
      font-size: rem-calc(12); 
      border-bottom: 1px solid rgb(255, 255, 255);

  }
  
  
}

// -------------------------  precontent ---/

#top{
  margin-top: $sticky-shrink-menu-height;
  background-color: rgba($dark-gray, 0.5);

  p, p.lead {
    color: $white;
    font-size: rem-calc(20);
    text-shadow: 1px 1px 2px $col1;
    @include breakpoint(medium) {
      font-size: rem-calc(32);
    }
  }

  #inc_container{
  min-height: $hero_wImg-Mheight;

    @include breakpoint(medium) {
    min-height: $hero_wImg-height;
    }

  } // #inc_container
} // #top


// -------------------------  main ---/
#main{
  //border-top: 8px solid $primary-color; 
  @include breakpoint(medium) {

    .pt_border {
      border-left: 2px solid $col3;
      border-right: 2px solid $col4;
    }
  
  }



}

.lead {
  padding-bottom:rem-calc(18);
  font-weight: 600;
}

.d_title {
  margin: rem-calc(12 0 24);
  padding-left:rem-calc(50);
  background-image: url('../img/logos/sdots.png');
  background-position: 0% 50%;
  background-repeat: no-repeat;

  h3,h3.h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding:rem-calc(0);
    margin-bottom: rem-calc(-6);

    //border-bottom: 4px solid $col3;
    
      ///display: flex;
      ///flex-flow: row;
      //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
      ///justify-content: flex-start;
      //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
      ///align-items: flex-start;

  }

  .col4 {
    padding-right: rem-calc(4);
  }

  p {
    margin: rem-calc(0 0);
    //margin-top: rem-calc(-20);
    //padding-left: rem-calc(58);
    text-transform: uppercase;
    font-weight: 600;
    color: $col3;
    font-size: 90%;
  }

}

.col4 {
  color: $col4;
}

.col3 {
  color: $col3;
}



// -------------------------  main/presentaion ---/

.present1{
  padding: rem-calc(6 0);
  display: flex;
  flex-flow: row;
  //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
  justify-content: flex-start;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: center;

  img {
      padding-left: rem-calc(0);
      padding-right: rem-calc(24);
  }
}



// -------------------------  padding_tb ---/

.padding_tb {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(40);
}



/**************************************************************  toptitle */
#toptitle{
  margin-top:rem-calc(18);
  margin-bottom:rem-calc(18);

  @include breakpoint(medium) {
    margin-top:rem-calc(18);
    margin-bottom:rem-calc(80);
  }

}

// -------------------------  Slider ---/
#slider_container{
  //padding-top: $sticky-shrink-menu-height;
  //height: 100vh;
}

.swiper-slide {
}

.swiper-slide-inner {

  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
  justify-content: center;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: center;


  .slider_title {
  display: flex;
  flex-direction: row;
  //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
  justify-content: center;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: flex-start;

  color: $white;
  font-family: 'simonettaregular', sans-serif; //
  text-shadow:  0 0 1em $col1, 0 0 0.2em $col1;

  font-size: 1.2rem;
  padding: rem-calc(8 0);


  margin-top: rem-calc(12);
  margin-bottom: rem-calc(4);
  margin-left: 5%;
  margin-right: 5%;

    @include breakpoint(medium) {
      font-size: 2.0rem;
      text-align: center;

      margin-top: rem-calc(12);
      margin-left: 10%;
      margin-right: 10%; 
    }

    @include breakpoint(large) {
      font-size: 3.6rem;
    }

    @include breakpoint(xlarge) {
      font-size: 3.8rem;
    }

    @include breakpoint(xxlarge) {
      font-size: 4.0rem;
    }

  } 

  p.slider_description {
  display: flex;
  flex-direction: row;
  //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
  justify-content: center;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  align-items: flex-start;

  color: $white;
  font-family: 'DM Sans', sans-serif;
  text-shadow:  0 0 1em $col1, 0 0 0.2em $col1;
  font-size: 0.9rem;

  padding: rem-calc(4 0);
  margin-left: 10%;
  margin-right: 10%;

    @include breakpoint(medium) {
      font-size: 1.4rem;
      text-align: center;

      margin-top: rem-calc(6);
      margin-left: 10%;
      margin-right: 10%;
    }

    @include breakpoint(large) {
      font-size: 2.4rem;
    }

    @include breakpoint(xlarge) {
      font-size: 2.6rem;
    }

    @include breakpoint(xxlarge) {
      font-size: 2.8rem;
    }
    
  }

}


/**************************************************************  widgets : widget-container */
.widget-container{
  padding-top:rem-calc(12);
  padding-bottom:rem-calc(12);

  @include breakpoint(medium) {
    padding-top:rem-calc(40);
    padding-bottom:rem-calc(40);
  }



  h1,h2,h3 {
    color: $text-dark;
    padding-bottom: rem-calc(24);
    //text-transform: uppercase;
  }
  h4,h5,h6 {
    color: $text-dark;
    padding-bottom: rem-calc(24);
    //text-transform: uppercase;
  }

}
// -------------------------  accueil_Widget ---/
#accueil_Widget{
  padding-top:rem-calc(12);
  padding-bottom:rem-calc(12);

  @include breakpoint(medium) {
    padding-top:rem-calc(40);
    padding-bottom:rem-calc(40);
  }
  a {
    color: $body-font-color;
  }
}


// -------------------------  widget_actualites0 ---/
#widget_actualites0{
  background-color:rgba($black, 0.9);
  
  h1,h2,h3 {
    color: $white;
  }

  .fb-post{
    background-color:$white;

  }
}


// -------------------------  widget_actualites ---/
#widget_actualites,
#actualite{
    @include breakpoint(medium) {
      //padding: rem-calc(36 0);
    }
  border-top: 8px solid $col3;
  border-bottom: 8px solid $col3;

  .actualites_bg{
    @include breakpoint(medium) {
      padding: rem-calc(100 0);
    }

  }

  h2 {
    color: $col3;
  }

    
    .actu_header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      i {
      content: url('../img/actu/fb_profile_image.png');
      padding:rem-calc(0 8 0 0);
      //vertical-align: -50%;
      }      

      h4 {
        color: $dark-gray;
        //font-family: Helvetica, Arial, FreeSans, sans-serif;
        font-size: 96%;
        font-weight: 600;
        margin:rem-calc(0 0 0 0);
        padding:rem-calc(0 0 0 0);
      }

      //h4::before {
      //content: url('../img/actu/fb_profile_image.png');
      //padding:rem-calc(0 8 0 0);
      //vertical-align: -50%;
      //}

      p {
        color: $bodyGrey;
        font-size: 80%;
        text-align: right;
        margin:rem-calc(0 0 0 0);
        padding:rem-calc(0 0 0 24);
      }
    }  

    .actu_section {
      display: block;
      flex-direction: column;
      flex-wrap: nowrap;
      min-height: rem-calc(100);

      p {
        color: $bodyGrey;
        font-size: 80%;

      }
    }  

  }


// -------------------------  widget_equipes ---/
#widget_equipes{

    
  @include breakpoint(medium) {

  }

  p {

  }
  .button.hollow{
    color: $dark-gray;
    border-color: $dark-gray;
  }
  .maillot_container{
    padding-bottom: rem-calc(18);
  }

}


// -------------------------  widget_historic ---/
#widget_historic{

    
  @include breakpoint(medium) {

  }

  p {

  }
  .button.hollow{
    color: $dark-gray;
    border-color: $dark-gray;
  }
  .historic_container{
    padding-bottom: rem-calc(18);
  }

}


// -------------------------  widget_teampage ---/
#widget_teampage{

  .team_website{
      padding: rem-calc(32 0);
      display: flex;
      flex-flow: row;
      //justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;  
      justify-content: center;
      //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
      align-items: center;

    i {
      padding: rem-calc(0 8);
    }
  }

}


// -------------------------  partenaires_list_widget ---/
#partenaires_list_widget{
  .partenaires_list{
  //padding-top:rem-calc(12);
  padding-bottom:rem-calc(24);
  }
  
  .partenaires_cont{
    border-radius: rem-calc(12);
    border: 1px solid $dark-gray;
    margin-bottom:rem-calc(12);
  }
}


// ------------------------- widget_partenaires ---/
#widget_partenaires{
  border-top: 4px solid $col3;
  border-bottom: 4px solid $col3;
}

// ------------------------- widget_interested---/
#interested{
  //padding-top: rem-calc(48);
}

  #parallax-interested{
    background-image: url('../img/parallax/interested.jpg');
}


  .parallax_bg{
    padding: rem-calc(50 0);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: 100% auto;
    position: relative;
    
    @include breakpoint(medium) {
      padding: rem-calc(100 0);
    }

  }

  .parallax_section {
    padding: rem-calc(18 0); 
    text-align: center; 

    p {
      text-align: center;
      color: $white; //$col1;
      text-shadow: 1px 1px 2px $col1;
    }
    .button {
      text-align: center;
      color: $white;

    }


  }



/**************************************************************  Forms */
#form_contact{
  padding:rem-calc(36 0);
  caret-color: $text-light;

  .errors{
    margin-top: rem-calc(-12);
    margin-bottom: rem-calc(18);    
  display: flex;
  flex-wrap: wrap;

    i{
    color: #e4134f;
    line-height: rem-calc(14);
    font-size: rem-calc(36);
    }
    p{
    margin:0;
    padding:rem-calc(6 6 6 6);
    color: $white;
    background-color: #e4134f;
    }
  }

  p{
    color: $text-dark;
  }
  p:nth-child(1) {
      @include breakpoint(large) {
      font-size: 2rem;
    }
  }
  p:nth-child(2) {

  }
  p:nth-child(3) {

  }
  p:nth-child(4) {
    margin-bottom:rem-calc(60);
  }

  label {
    color: $col1;

  }

  input {
    border-bottom:1px solid $col1;

  }

  textarea {
    border-radius:0;
    border-bottom:1px solid $col1;
  }

}


/**************************************************************  Contact */

#contact {
  .contact_infos {
      margin-top:rem-calc(24);
      margin-bottom:rem-calc(12);

      @include breakpoint(large) {
        margin-top: rem-calc(12);
      }
  }

  h4 {
      padding-bottom: rem-calc(18);

      @include breakpoint(large) {
        padding-bottom: rem-calc(18);
      }

      //font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      font-style: normal;
      color: $text-dark;
  }

  p {
      //font-family: 'Montserrat', sans-serif;
      font-weight: 200;
      font-style: normal;
  }

  p.subTitle{
  color: $col2;
  margin: rem-calc(8 0 0 0);
  }

  p.entry{
  color: $text-dark;
  font-size:110%;
  margin: 0;
  }

  .icon-box{
    display: flex;
    align-items: center;
    //justify-content: space-around;

    i{
      padding-right: rem-calc(12);
    }
  }

}


/**************************************************************  #footer */
$footer-bg: $col1;

#footer {
    
    padding-top:rem-calc(18);padding-bottom:rem-calc(6);
    background-color: $footer-bg;


.sig-line {
  display: flex;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  justify-content: center;
  line-height: 1.6rem;
  margin-top:rem-calc(18);margin-bottom:rem-calc(18);
  //font-family: 'Montserrat', sans-serif;

  @include breakpoint(medium) {
    margin-top: rem-calc(18);margin-bottom: rem-calc(36);
  }

  h5 {
    margin: 0;
    padding: 0;
    text-align: center;
    color: $col6;
    line-height: 1.6rem;
    //font-family: 'Montserrat', sans-serif;
  }
}

.sig-line::before, .sig-line::after {
    content: '';
    display: inline-block;
    flex-grow: 1;
    margin-top: 0.8rem;
    background: lighten($footer-bg, 30%);
    height: 2px;
    margin-right: rem-calc(12);
    margin-left: rem-calc(12);

    @include breakpoint(medium) {
    margin-right: rem-calc(24);
    margin-left: rem-calc(24);
    }
  }

  .footer-title {
    color: $col6;
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(12);
    padding-bottom: rem-calc(6);
    //border-bottom: 1px solid lighten($footer-bg, 20%);
    text-align: center;

    @include breakpoint(medium) {
      margin-top: rem-calc(0);
      border: none;
    }
  }

  .footer-block {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .fa {
      //font-size: 2rem;
      color: $col6;
    }
    a {
      color: $col3;
    }
    & a.is-active{
      color: $col6;
    }
    & a:hover {
      color: $col6;
    }
    p {
      margin: 0;
      margin-left: 1rem;
    }

  }


  .footer-bottom-links{

    li{
      padding:rem-calc(0 0 16 0);
      a{
        color: $col3;
      }
      & a.is-active{
        color: $col6;
      }
      &:hover a{
        color: $col6;
      } 
    }

  }

  .footer-social{
    justify-content: center;

    li{
      padding:rem-calc(0 0 16 0);
      a{
        color: $col6;
      }
      &:hover a{
        color: $col7;
      } 
    }

    @include breakpoint(medium) {
      border: none;
    }
  }

  .up-arrow{
  color: $col6;
  width: 100%;

  display: flex;
  justify-content: flex-end;

      a{
        color: $col6;
        padding:rem-calc(6 6 3 6);
        &:hover {
          color: $col7;
        }          
      } 

  }

  .footer-notice{
  color: $col6;
  text-align: center;
  margin: 0;
  padding: 0;
  //margin-left: 1rem;
  //line-height: 1.0rem;

    @include breakpoint(medium) {
      text-align: center;
    }

  }

}


/************************************************************** OFFLINE */

